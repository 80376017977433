import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nuestros Servicios</h2>
          <p>
          En EnfermeraHoy.cl, ofrecemos una amplia gama de servicios especializados para atender tus necesidades de salud en el hogar. Nuestro equipo de expertos se dedica a brindarte el cuidado y la atención que mereces, con la comodidad y seguridad que necesitas.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
               </div>
              ))
            : "loading"}
        </div>
       
  <hr></hr>
            <span>
          Ofrecemos una amplia gama de servicios de atención médica en domicilio, incluyendo hospitalización domiciliaria, administración de medicamentos, inyecciones, kinesiología, terapia ocupacional, y más. Nuestro equipo de profesionales de la salud está capacitado para brindar cuidado personalizado y de alta calidad en la comodidad de tu hogar. Si tú o tus seres queridos necesitan otros servicios médicos o de apoyo, no dudes en consultarnos para obtener más información y recibir el cuidado que merecen.
          </span>    
         
     
      </div>
    </div>
  );
};
