import { useState } from "react";
import React from "react";

const initialState = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, phone, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const data = {
      name,
      email,
      phone,
      message,
    };
  
    try {
      const queryString = new URLSearchParams(data).toString();
      console.log(queryString);
      const url = `https://script.google.com/macros/s/AKfycbx-juSy0fxigadYJaazb8fbcUbX-4meYoU7KYkWGVloQKfOvVkYIBlrWi1-f7xSfhRT/exec?${queryString}`;
      console.log(url);
      const response = await fetch(url, {
        method: "GET",
        mode: 'cors', 
      });
  
  console.log(response)
    
      if (response.ok) {
        console.log("Response OK:");
        alert("Mensaje enviado exitosamente");
      } else {
        console.log("Response not OK:");
        alert("Hubo un problema al enviar el mensaje.");
      }
  
    } catch (error) {
      console.error("Error en la solicitud:",error);
      alert("Error al enviar el mensaje. Por favor, inténtalo de nuevo.");
    }
    
    clearState(); // Limpiar el formulario
  };
  

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Contáctanos</h2>
                <p>
                  Por favor, completa el formulario a continuación para enviarnos un correo electrónico y nos pondremos en contacto contigo lo antes posible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        className="form-control"
                        placeholder="Nombre"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        value={email}
                        name="email"
                        className="form-control"
                        placeholder="Correo Electrónico"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    id="phone"
                    value={phone}
                    name="phone"
                    className="form-control"
                    placeholder="Número de Teléfono"
                    required
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    value={message}
                    className="form-control"
                    rows="4"
                    placeholder="Mensaje"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar Mensaje
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Información de Contacto</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Dirección
                </span>
                {props.data ? props.data.address : "cargando"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Teléfono
                </span>{" "}
                {props.data ? props.data.phone : "cargando"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Correo Electrónico
                </span>{" "}
                {props.data ? props.data.email : "cargando"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.whatsapp : "/"}>
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 EnfermeraHoy.cl Todos los derechos Resservados{" "}
            <a href="https://www.enfermeraHoy.cl" rel="nofollow">
              enfermeraHoy.cl
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
